<template>
	<div>
		<main-nav :activeIndex="activeIndex"></main-nav>
		<div class="solution-header-container">
			<div class="solution-header-text">
				<h1 data-caption-delay="0" class="wow fadeInLeft">工具软件定制开发</h1>
				<p data-caption-delay="100" class="wow fadeInRight">工具类软件开发服务及基于成熟产品的应用方案构建</p>
				<div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
					<div>立即咨询</div>
				</div>
			</div>
		</div>
		<div class="sw-tab-title" ref="tab" :class="tabFixed ? getTabFixedClass() : ''">
			<div class="container">
				<el-tabs v-model="activeProduct" @tab-click="handleTabClick">
					<el-tab-pane label="上位机软件开发" name="1">上位机软件开发</el-tab-pane>
					<el-tab-pane label="总线数据转换" name="2">总线数据转换</el-tab-pane>
					<el-tab-pane label="工具适配互通" name="3">工具适配互通</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<div :class="tabFixed?'solution-tab-fixed':''">
			<div v-if="activeProduct === '1'">
				<section class="solution-intros wow fadeInLeft">
					<div class="container">
						<el-row>
							<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
								<div class="normal-title">解决方案介绍</div>
								<div class="all-intro">
									<div class="intro-detail">
										<p>
											随着汽车电子电气架构的日益复杂，对监控、控制和分析车辆电子系统的各类上位机软件也提出了新的挑战和需求。为了适配新的硬件接口、通讯协议等功能，同时提供更精确、可靠的数据分析和控制能力，标准化的上位机软件往往无法满足需求。所以需要针对客户的各类个性化需求，进行定制化的上位机软件开发服务。
										</p>
									</div>
									<div class="intro-detail">
										<p>配置类工具定制开发：网关配置工具等；</p>
										<p>转换类工具定制开发：路由表转换、报告转换等；</p>
										<p>刷写工具定制开发：硬件适配、刷写工具定制开发；</p>
										<p>其他工具定制开发：其他设备协同类上位机软件开发。</p>

									</div>
								</div>
							</el-col>

						</el-row>
					</div>
				</section>
				<section class="vehicle-solution-sections wow fadeInRight">
					<div class="container">
						<div class="normal-title">功能特点</div>
						<el-row>
							<el-col :span="24">
								<p class="intro-detail">
									东信创智面向客户的各类业务需求，提供定制化软件开发服务与解决方案，主要包括桌面工具、管理系统、数据分析等方向，从而帮助客户提升工作效率、规范操作流程、精细化业务管理。我们的优势如下：
								</p>
							</el-col>
						</el-row>
						<div class="all-function">
							<ul class="priority-list">
								<li>汽车电子行业经验积淀：深度理解汽车电子行业标准及实践方法论</li>
								<li>IT软件行业技能积累：全面掌握软件系统开发的各种技术与工具</li>
								<li>量身定制，随需而变：架构灵活、功能全面，从客户需求出发，为客户量身定制个性化软件</li>
							</ul>
						</div>
					</div>
				</section>

				<section class="dyna-product-system-picture wow fadeInLeft">
					<div class="container">
						<div class="normal-title">系统图片</div>
						<div class="all-system-picture">
							<div class="system-picture">
								<img src="../../../assets/images/solutions/tool-01.png"
									style="width: 80%;height: 80%;border: 1px solid #ddd" alt="">
							</div>
						</div>
					</div>
				</section>
			</div>
			<div v-if="activeProduct === '2'">
				<section class="solution-intros wow fadeInLeft">
					<div class="container">
						<div class="normal-title">解决方案介绍</div>
						<div class="all-intro">
							<div class="intro-text">
								<p>总线通信数据库（dbc/ldf等）作为描述整车通信及ECU之间功能交互的重要技术文档，贯穿整个研发环节。诊断数据库（cdd/odx）作为针对诊断和刷写数据的数据格式，极大的方便了不同供应商之间、供应商与整车厂之间、开发与售后之间数据交换。
								</p>
								<p>总线通信数据库及诊断数据库的开发工程师往往更习惯采用Excel形式进行文件定义，但在整车研发的过程中，很多业务工作需要基于标准数据库开展，因此有很多场景都需要进行Excel和数据库的转换工作。人工手动进行文件转换，不仅需要花费大量的时间成本，而且容易出错，从而严重影响车辆研发进度。
								</p>
								<p>为了解决上述问题，东信创智提供数据转换服务解决方案来满足各项数据转换需求。</p>
							</div>
						</div>
					</div>
				</section>
				<section class="vehicle-solution-sections wow fadeInRight">
					<div class="container">
						<div class="normal-title">功能特点</div>
						<div class="all-function">
							<ul class="priority-list">
								<li>通过自动读取通信矩阵(Excel)文件和诊断调查问卷(Excel)文件中的内容，快速生成通信数据库及诊断数据库文件</li>
								<li>支持格式校验和内容校验，同时提供错误信息提示和报告，帮助使用者快速定位转换问题</li>
							</ul>
						</div>
					</div>
				</section>
				<section class="electric-solution-components network-solution-components hil-components wow fadeInLeft">
					<div class="container" style="height: 530px">
						<div class="normal-title">核心组件</div>
						<div class="solution-components">
							<el-row class="components-content" :gutter="40">
								<el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
									<img src="../../../assets/images/solution-hil-components.png" alt="">
								</el-col>

								<el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
									<div class="components-text">
										<div>
											<h1>数据解析</h1>
											<p>按照定制化的矩阵模板完成数据解析</p>
										</div>
										<div :style="isMobile ? 'margin-top: 20px' :'margin-top: 59px'">
											<h1>数据校验</h1>
											<p>按照定制化的校验内容完成数据校验</p>
										</div>
										<div :style="isMobile ? 'margin-top: 20px' :'margin-top: 51px'">
											<h1>数据转换</h1>
											<p>按照定制化的输出内容完成数据转换</p>
										</div>
										<div :style="isMobile ? 'margin-top: 20px' :'margin-top: 51px'">
											<h1>日志报告</h1>
											<p>按照定制化的报告格式输出日志报告</p>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</section>
				<section class="solution-application wow fadeInRight">
					<div class="container">
						<div class="normal-title">应用领域</div>
						<div class="all-application">
							<div class="all-application">
								<div class="application-content">
									<div class="application-icon">
										<img src="../../../assets/images/application-picture.svg" alt="">
									</div>
									<div class="application-text">
										<p>汽车电子通信/诊断功能设计、开发、测试</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section class="dyna-product-system-picture wow fadeInLeft">
					<div class="container">
						<div class="normal-title">系统图片</div>
						<el-row class="components-content" :gutter="40">
							<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
								<img src="../../../assets/images/solution-tool-system-pic-2.png" alt=""
									style="width: 95%;">
							</el-col>
							<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" style="text-align: right">
								<img src="../../../assets/images/solution-tool-system-pic-1.png" alt=""
									style="width: 105%;">
							</el-col>
							<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
								<img src="../../../assets/images/solution-tool-system-pic-3.png" alt=""
									style="width: 105%;">
							</el-col>
						</el-row>

					</div>
				</section>
			</div>
			<div v-if="activeProduct === '3'">
				<section class="solution-intros wow fadeInLeft">
					<div class="container">
						<el-row>
							<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
								<div class="normal-title">解决方案介绍</div>
								<div class="all-intro">
									<div class="intro-text">
										<p>工具适配互通解决方案基于LabVIEW及CANoe提供的可编程接口，实现与其他测试管理工具、业务系统、云平台的数据互通，可极大程度的提升LabVIEW及CANoe的应用范围，缩短项目周期、降低项目成本。
										</p>
									</div>
								</div>
							</el-col>

						</el-row>
					</div>
				</section>
				<section class="vehicle-solution-sections wow fadeInRight">
					<div class="container">
						<div class="normal-title">功能特点</div>
						<el-row>
							<el-col :span="24">
								<p class="intro-detail">
									东信创智面向客户的各类业务需求，提供定制化软件开发服务与解决方案，主要包括桌面工具、管理系统、数据分析等方向，从而帮助客户提升工作效率、规范操作流程、精细化业务管理。我们的优势如下：
								</p>
							</el-col>
						</el-row>

						<div class="all-function">
							<ul class="priority-list">
								<li><span>数据互通</span>可与第三方工具、业务系统、各类平台等进行适配和数据交互</li>
								<li><span>数据处理</span>可应对各类复杂数据场景，进行数据解析、数据转换、数据存储等处理</li>
								<li><span>硬件对接</span>可对接各类硬件设备</li>
							</ul>
						</div>
					</div>
				</section>
				<section class="dyna-product-system-picture wow fadeInLeft">
					<div class="container">
						<div class="normal-title">系统图片</div>
						<el-row class="components-content" :gutter="40">
							<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
								<img src="../../../assets/images/solution-tool-system-pic-10.png" alt=""
									style="width: 95%;">
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="text-align: right">
								<img src="../../../assets/images/solution-tool-system-pic-11.png" alt=""
									style="width: 105%;">
							</el-col>
							
						</el-row>

					</div>
				</section>
				
		</div>
	</div>
	<bzInfo style="margin-top: 0" />
	<main-footer></main-footer>
	</div>
</template>

<script>
	import mainNav from "@/components/MainNav.vue";
	import mainFooter from "@/components/MainFooter.vue";
	import blueBg from "@/assets/images/solution-eletric-function-bg.png";
	import bzInfo from "@/components/BzInfo.vue";

	export default {
		name: "toolSoftware",
		components: {
			mainFooter,
			mainNav,
			bzInfo
		},
		data() {
			return {
				tabFixed: false,
				activeIndex: '2-7',
				blueBg: blueBg,
				activeProduct: '1',
				swiperOption: {
					spaceBetween: 10, // 图片之间的间距
					centeredSlides: false, // 居中还是从图1开始
					slidesPerView: 2, // 一屏幕显示几个? 数字或者默认  auto 自动。
					breakpointsInverse: true,
					breakpoints: {
						//当宽度大于等于1100
						1100: {
							slidesPerView: 4,
							spaceBetween: 10
						}
					},
					notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
					// loop: true, // 循环吗
					initialSlide: 0, // 从第几个开始
					autoplay: {
						delay: 5000, // 等5秒下一个
						disableOnInteraction: false // 中间滑动一下，取消自动吗？
					},
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					}, // 下按钮
					speed: 800, // 滑动时候动画的速度
					paginationClickable: true, // 下面按钮让点吗
					navigation: {
						prevEl: '.swiper-button-prev', // 左侧按钮
						nextEl: '.swiper-button-next' // 右侧按钮
					},
					// effect: 'fade', // 渐入效果
					watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
					watchSlidesVisibility: true // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
					// autoHeight: true  // 图片高度自适应
				},
				functionList1: [{
						title: "创建测试工程",
						content: "支持基于测试脚本模板进行变量管理，快速生成测试工程"
					},
					{
						title: "配置测试队列",
						content: "支持动态配置测试队列并启动执行任务"
					},
					{
						title: "对接测试工具",
						content: "包括CANoe / vTESTstudio / TTworkbench / ECU-TEST / VeriStand / TestStand/ dSPACE / LabVIEW / 客户自研工具等"
					},
					{
						title: "自定义测试报告",
						content: "实时显示测试结果，支持测试问题记录与过程跟踪"
					},
					{
						title: "过程跟踪",
						content: "提供系统的用户管理、部门管理、岗位管理、角色管理、权限管理、菜单管理、菜单授权、字典配置等配置管理"
					},
					{
						title: "协同测试",
						content: "支持多角色、多角色与设备跨地域协同工作（服务器版）"
					},
					{
						title: "测试管理",
						content: "支持实验室管理、设备管理、测试用例管理、测试需求管理、测试计划管理、测试样件管理、测试执行管理、测试问题管理闭环管理机制（服务器版）"
					},
					{
						title: "远程测试",
						content: "支持远程自动化测试与多设备并行测试（服务器版）"
					},
					{
						title: "远程调试",
						content: "系统用户可通过系统远程控制上位机进行调试解决问题（服务器版）"
					},
					{
						title: "集中监管",
						content: "提供不同角色工作门户，按需定制展示内容，提供中控大屏进行集中监管（服务器版）"
					},
				],
				functionList2: [{
						title: "硬件对接",
						content: "在测试用例中对接电源、串口、ADB的命令控制等内容，将测试相关的硬件功能集成到CANoe的测试能力里，扩展CANoe的测试范围"
					},
					{
						title: "工具对接",
						content: "通过CANoe调用NI、dSPACE或用户自研等专业工具执行多维度的测试功能，通过业务参数传递业务需求及执行结果，实现业务联动"
					},
					{
						title: "系统/服务对接",
						content: "与各类业务管理系统、云平台等进行服务对接。也可通过调用其他中间件或Windows服务，执行外部服务的相关功能"
					},
					{
						title: "数据处理",
						content: "对传感器、设备或其他数据源采集的数据进行二次加工,包括数据清洗、数据转换、数据存储、数据分析等，满足不同技术、业务连接需要"
					},
					{
						title: "后台服务",
						content: "将CANoe功能封装为中间件或Windows服务。这些组件通过后台运行的方式处理数据和执行任务，可提高系统的可靠性和稳定性"
					},
					{
						title: "逻辑编排",
						content: "支持各类业务场景的逻辑编排，可基于场景测试时序队列，实现跨工具、跨系统、跨业务的流程测试，实现基于业务逻辑的场景编排与业务执行"
					},
				],
				chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
			}
		},
		mounted() {
			document.title = "工具软件定制开发 - 解决方案 - 东信创智"
			new this.$wow.WOW().init()
			window.addEventListener("scroll", this.handleScroll)
		},
		destroyed() {
			window.removeEventListener("scroll", this.handleScroll);
		},
		methods: {
			linkUrl(url) {
				window.open(url, '_blank') // 在新窗口打开外链接
				// window.location.href =this.indexro;  //在本页面打开外部链接
			},
			handleScroll() {
				if (document.documentElement.clientWidth > 1100) {
					let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 70
					if (top < 0 && top > -630) {
						this.tabFixed = !this.tabFixed;
					} else if (document.documentElement.scrollTop > 630) {
						this.tabFixed = true;
					}
				} else {
					let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 50
					if (top < 0 && top > -250) {
						this.tabFixed = !this.tabFixed;
					} else if (document.documentElement.scrollTop > 250) {
						this.tabFixed = true;
					}
				}
			},
			getTabFixedClass() {
				if (document.documentElement.clientWidth < 1100) {
					return 'sw-tab-title-mobile-fixed'
				} else {
					return 'sw-tab-title-fixed'
				}
			},
			handleTabClick() {
				if (document.documentElement.clientWidth > 1100 && document.documentElement.scrollTop > 630) {
					document.documentElement.scrollTop = 630
				} else if (document.documentElement.clientWidth < 1100 && document.documentElement.scrollTop > 250) {
					document.documentElement.scrollTop = 250
				}
			}
		}
	}
</script>